<template>
  <div id="apps">
    <AddGoogleMap />
  </div>
</template>
 
<script>
import AddGoogleMap from "../components/MapGoogle.vue";

export default {
  name: "Map",
  components: {
    AddGoogleMap,
  },
};
</script>
 
<style>
#apps {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin-top: 50px;
}
</style>