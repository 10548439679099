
<script>
import { APIS } from "./constantes/apis";
import $ from "jquery";
export default {
  data: function () {
    return {
      footer: true,
      APIS: APIS,
      statys: false,
      idEntidad: "id",
      titlePage: "",
      social: {
        facebookPage: "https://www.facebook.com/",
        twitterPage: "https://www.twitter.com/",
        instagramPage: "https://www.instagram.com/",
        youtubePage: "https://www.youtube.com/",
      },

      colorpPage: "",
      colorsPage: "",
      logovPage: "url",
      logohPage: "url",
      copyright: "",
      contacto: "",
      correo: "",
      favicon: "",
      lema: "Juntos como nunca",
      header: {
        latitud: "",
        longitud: "",
        idEntity: null,
        token: "",
      },
    };
  },
  beforeMount() {
    this.getLocate();
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    const body = { id_entity: process.env.VUE_APP_ENTIDAD };
    this.$http
      .post(this.APIS[1].name, body)
      .then((response) => {
        this.header.token = response.data.token;
        this.logohPage = response.data.data[0].logo_horizontal;
        this.colorpPage = response.data.data[0].color_primario;
        this.colorsPage = response.data.data[0].color_secundario;
        this.copyright = response.data.data[0].copyright;
        this.contacto = response.data.data[0].contacto;
        this.correo = response.data.data[0].correo;
        this.social.facebookPage = response.data.data[0].facebook;
        this.social.twitterPage = response.data.data[0].twitter;
        this.favicon = response.data.data[0].favicon;
        this.header.idEntity = 1;
        window.document.title = response.data.data[0].titulo_pagina;
        this.titlePage = response.data.data[0].titulo_pagina;
        $(":root").css("--colorP", this.colorpPage);
        $(":root").css("--colorS", this.colorsPage);
        link.href = this.favicon;
      })
      .catch((error) => {
        console.log('error en peticion ', error);
        this.$router.push("/error");
        return 404;
      });
  },
  methods: {
    async getLocate() {
      var globales = this;
      navigator.geolocation.getCurrentPosition(function (position) {
        globales.header.longitud = position.coords.longitude;
        globales.header.latitud = position.coords.latitude;
        //console.log(globales.header.latitud);
      });
    },

    listen(variable) {
      console.log(variable);
    },
    closeSesion() {
      this.$session.clear();
      this.$session.destroy();
      window.location.href = "/";
      this.$router.push("/");
    },
  },
  mounted: function () { },
};
</script>

<template>
  <div id="app">
    <div id="nav">
      <img class="icon-nav" :src="this.logohPage" />
      <!-- <router-link to="/registro">Registro</router-link> |
      <router-link to="/inicio">Login</router-link> -->
      <div v-show="this.$session.exists()" id="content-user">
        <div id="exit"><a href="#" @click="closeSesion">Salir</a></div>
      </div>
    </div>

    <router-view :APIS="APIS" :copyright="this.copyright" :title="this.titlePage" :header="this.header"
      :social="this.social" @listening="listen" />

    <!-- 
    <div v-if="!this.$session.exists()" id="footer">
      <p>{{this.copyright}}</p>
      <a :href="this.facebookPage" target="_blank"> <i class="fa fa-facebook-official" aria-hidden="true"></i></a>
      <a :href="this.twitterPage" target="_blank"><i class="fa fa-twitter-square" aria-hidden="true"></i></a>
      <a :href="this.instagramPage" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
      <a :href="this.youtubePage" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
    </div>
    -->
  </div>
</template>

<style scoped>

</style>
